import PropTypes from 'prop-types';
import React from 'react';
import style from './Feature.module.scss';

Feature.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired
};

function Feature({ title, icon, detail }) {
    return (<div className={style.Feature}>
        <div className={style.Feature__icon}><img src={icon} alt={title || detail} className={style.Feature__icon__img} /></div>
        {title && <div className={style.Feature__title}>{title}</div>}
        <div className={style.Feature__detail}>{detail}</div>
    </div>);
}

export default Feature;
