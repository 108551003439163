export const reviews = [
    {
        id: 1,
        review: 'Easy purchase easy payment. Absolutely this is the first app that provide easy instalment plan for those who wish to get branded gadget but can\'t afford to pay full payment.  Best part is Cinch provide convenient payment system where we can choose how long we can afford to make the payment and even can purchase the item anytime we wish to. Not that but also they provide damage covering all the way while we still under the subscription. I hope Cinch and samsung will soon provide more products not only handphone and tablet but also consider about accessories too such as Bluetooth earpiece , casing and other electronic items too. So far using this app I\'m really satisfied with the fast customer service and useful answer to all enquiries.',
        author: 'Fit Busker | a week ago',
        rating: 5
    },
    {
        id: 2,
        review: 'Very good support and fast delivery. Item rented are of very good quality!',
        author: 'Nicky Wong | 3 weeks ago',
        rating: 5
    },
    {
        id: 3,
        review: 'Exemplary attention to detail and proactive customer service provided by Angie, thank you so much! Would highly recommend to others looking to rent the latest tech.',
        author: 'A K T | 3 weeks ago',
        rating: 5
    },
    {
        id: 4,
        review: 'Got my device earlier than expected and the customer service was excellent as they answered my queries and followed up diligently',
        author: 'Azman Bin Abdullah | a month ago',
        rating: 5
    },
    {
        id: 5,
        review: 'Phone came with Good Condition. So far working Excellent.',
        author: 'Hirose Takayuki | a month ago',
        rating: 5
    },
    {
        id: 6,
        review: 'Got my device earlier than expected and the customer service was excellent as they answered my queries and followed up diligently',
        author: 'Azman Bin Abdullah | a month ago',
        rating: 5
    },
    {
        id: 7,
        review: 'Great experience and hasslr free.customer service is always prompt in responding to my queries.2 thumbs up!!!!',
        author: 'Azad S. | a month ago',
        rating: 5
    },
    {
        id: 8,
        review: 'At fisrt I was skeptical of using this service but I was surprised by their service. The device was in excellent condition and I\'m suprised by how fast they delivered the device to my doorstep. Will definitely keep using their services',
        author: 'Nurman | a month ago',
        rating: 5
    },
    {
        id: 9,
        review: 'Professionally and good customer service and product and quality of gadgets 100% like 1st hand condition. Rating: 10/10 Keep it up Cinch, 100% recommended to families and friends.',
        author: 'Matshuk | a month ago',
        rating: 5
    },
    {
        id: 10,
        review: 'I am amazed by the service. I am a new customer wanting to try their service. While waiting for my order. My current phone suddenly decided to fail me. To my surprise Cinch make special arrangement after hearing my situation and I got my phone within an hour. This is remarkable 👏 a true customer experience. I am deeply grateful for their supports especially to Maniyn whom assisted me to make this arrangement.',
        author: 'Raymond Chua | 4 months ago',
        rating: 5
    }
];
