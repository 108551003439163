import { AUTH_API_URL, ELOQUENT } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken } from 'utils/helpers';

/**
 * login user
 * * 422 - Email or Password is wrong
 * @param {Object} params
 */

export async function login(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/auth/login'
    };

    try {
        const { data } = await axios(options);

        // console.log('access_token', data.access_token);

        return data;
    } catch (err) {
        const msg = err.response.data.errors.message || err.response.data.message;

        if (msg) {
            notify(msg, notifType.DANGER);
        }

        throw err;
    }
}

/**
 * Register user
 * ERRORS:
 * * 422 - email already exists
 * @param {Object} params
 */
export async function register(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/auth/register'
    };

    try {
        const { data } = await axios(options);

        // console.log('access_token', data.access_token);

        return data;
    } catch (err) {
        const status = err.response.status;

        if (status === 422) {
            notify('This email is already in use.', notifType.DANGER);
        }

        throw err;
    }
}

/**
 * Logout user
 * @returns {Promise}
 */
export async function logout() {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/auth/logout'
    };

    try {
        const resp = await axios(options);

        return resp;
    } catch (err) {
        return err;
    }
}

/**
 * request a password reset code by the given email
 * ERRORS:
 * * 404 - email not found
 * @param {String} email
 * @returns {Promise}
 */
export function requestPasswordReset(email) {
    return ELOQUENT.Auth.forgotPassword(email);
}

/**
 * login with OTP
 * ERRORS:
 * @param {Object} params
 */
export async function loginWithOTP(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/auth/login'
    };

    try {
        const { data } = await axios(options);

        return data.access_token;
    } catch (err) {
        // console.error(err);
        // const status = err.response.status;

        // if (status) {
        //     notify('This email is already in use.', notifType.DANGER);
        // }

        // throw err;
    }
}

/**
 * Get OTP for login
 * ERRORS:
 * * 422 - email already exists
 * @param {Object} params
 */
export async function getOTPForLogin(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/auth/login'
    };

    try {
        await axios(options);
    } catch (err) {
        const status = err.response.status;

        if (status === 422) {
            notify('The mobile number is invalid.', notifType.DANGER);
        }

        throw err;
    }
}

/**
 * reset the user's password
 * ERRORS:
 * * 404 - code not found
 * * 422 - code expired
 * @param {String} token
 * @param {String} email
 * @param {String} password
 * @param {String} password_confirmation
 * @param {String} pin
 * @param {String} pin_confirmation
 * @param {String} otp
 * @returns {Promise}
 */
// eslint-disable-next-line camelcase
export function resetPassword(token, email, password, password_confirmation, pin, pin_confirmation, otp) {
    return ELOQUENT.Auth.resetPassword(token, email, password, password_confirmation, pin, pin_confirmation, otp);
}
