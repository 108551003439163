import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import {
    Form,
    FormGroup,
    Input,
    Spinner,
    List,
    NavLink,
    UncontrolledAccordion,
    AccordionHeader,
    AccordionBody,
    AccordionItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Dropdown from 'react-multilevel-dropdown';
import { Collapse, Badge } from 'antd';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { notifType, notify } from 'utils/notifSender';
import _ from 'underscore';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { ROUTES } from 'config/constants';
import { Autocomplete } from './Autocomplete';
import ExploreItem from 'containers/App/components/Product/ExploreItem/ExploreItem';

import { getAutocompleteProducts } from 'api/products';
import {
    handleLogout,
    loginModal
} from 'redux/reducers/auth';

import NoImg from 'assets/images/noImg.png';
import mobileMenu from 'assets/images/menu.png';
import Home from 'assets/images/general/home.svg';
import CloseIcon from 'assets/images/general/close.svg';
import Logo from 'assets/images/general/logo.png';
import basket from 'assets/images/shopping-cart.png';
import SearchMobileIcon from 'assets/images/search-mobile.png';

import 'react-spring-bottom-sheet/dist/style.css';
import './SearchAdmin.scss';

const SearchAdmin = ({ singpassStatus, token, loginModal, stateForBottomSheet, productCategories }) => {
    const isMobile = window.matchMedia('(max-width: 1220px)').matches;
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const { pathname } = useLocation();
    const history = useHistory();
    const ref = useRef(null);
    const [searchQuery, setSearchQuery] = useQueryParam('query', StringParam);
    const [categQuery] = useQueryParam(
        'category[]',
        StringParam
    );
    const [isAutocompleteVisibile, setAutocompleteVisibility] = useState(false);
    const [search, setSearch] = useState(searchQuery);
    const [productsList, setProductsList] = useState([]);
    const [categsList, setCategsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const subcategories = productCategories && productCategories.map(item => item.children.map((x) => x));
    const subcatsArray = subcategories && [].concat(...subcategories);
    const allCats = subcatsArray && [...subcatsArray, ...productCategories];
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const searchParams = useLocation().search;
    const categoryId = new URLSearchParams(searchParams).get('category[]');
    const { products } = useSelector(state => ({
        products: state.cart.fetchedProducts,
        notifyHeader: state.cart.notifyHeader
    }));

    useEffect(() => {
        if (isSheetOpen) {
            document.body.classList.add('bg_freeze');
        } else {
            document.body.classList.remove('bg_freeze');
        }
    }, [isSheetOpen]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (!stateForBottomSheet) setIsSheetOpen(false);
    }, [stateForBottomSheet]);

    useEffect(() => {
        if (open) setOpen(false);
        resetSearch();
    }, [pathname, categoryId]);

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const handleClickOutside = (event) => {
        if (listRef.current && !listRef.current.contains(event.target) && !isMobile) {
            setAutocompleteVisibility(false);
        }
    };

    const goToCart = () => {
        if (token === '') {
            notify('You need to login!', notifType.WARN);
        } else {
            // history.repalce(ROUTES.CART);
            // Due to some react-router-dom version issues we cannot use the history object
            // And this will be addressed on its neweer version soon
            window.location.href = ROUTES.CART;
        }
    };

    const handleDropdownToggle = (e) => {
        if (ref.current) {
            ref.current.toggle(e);
        }
    };

    const handleCategorySearch = (categ) => {
        history.push({
            pathname: ROUTES.PRODUCTS,
            search: `?category[]=${categ}${search ? `&search=${search}` : ''}&rental_period=12`
        });
    };

    const renderTitle = () => {
        return (
            <div>
                <img
                    className='search-admin__category-icon'
                    src={Home}
                    alt='Icon for link to Homepage' />
                <FontAwesomeIcon
                    className='search-admin__down-icon'
                    icon={faAngleDown} />
            </div>
        );
    };

    const handleRedirection = () => {
        if (pathname !== '/products') {
            window.location.href = `${ROUTES.PRODUCTS}?query=${search}&rental_period=12`;
        } else {
            if (!search) {
                setSearchQuery(undefined);
            } else {
                setSearchQuery(search);
            }
        }
    };

    const handleSubmission = e => {
        e.preventDefault();
        setAutocompleteVisibility(false);

        handleRedirection();
    };

    const getProducts = async val => {
        setIsLoading(true);
        const prods = await getAutocompleteProducts(val);

        setProductsList(prods.data);
        setTimeout(() => setIsLoading(false), 1000);
    };

    const handleSearch = e => {
        setProductsList([]);
        const { value } = e.target;

        setSearch(value);

        if (value.length > 2) {
            if (isMobile) {
                if (isSheetOpen) {
                    setTimeout(() => {
                        setAutocompleteVisibility(true);
                    }, 1500);
                }
            } else {
                setAutocompleteVisibility(true);
            }

            getProducts(value);
            setCategsList(allCats.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setAutocompleteVisibility(false);
            setProductsList([]);
            setCategsList([]);

            if (value.length === 0) {
                resetSearch();
            }
        }
    };

    const resetSearch = () => {
        setSearch('');
        setProductsList([]);
        setAutocompleteVisibility(false);
    };

    const handleLogoutMobile = () => {
        setOpen(false);
        dispatch(handleLogout());
    };

    const handleBottomSheetCloseHandler = () => {
        setIsSheetOpen(false);
        setAutocompleteVisibility(false);
    };

    const hideDrawerOnItemSelection = () => {
        setAutocompleteVisibility(false);
        setIsSheetOpen(false);
    };

    const redirectToSearch = () => {
        window.location.href = ROUTES.SEARCH.path;
    };

    return (
        <>
            <Form className='container' onSubmit={handleSubmission} autocomplete='off'>
                <FormGroup className='search-admin position-relative'>
                    {!isMobile &&
                        <>
                            <Dropdown
                                title={renderTitle()}
                                position='right'
                                ref={ref}
                                className='search-admin__dropdown'>
                                {!!productCategories.length &&
                                    productCategories.map((item) => (
                                        <Dropdown.Item
                                            key={item.id}
                                            onClick={() => {
                                                handleDropdownToggle();
                                                handleCategorySearch(item.slug);
                                            }}
                                            className={classNames({
                                                'is-active': item.children.some(
                                                    child =>
                                                        child.id === parseInt(categQuery)
                                                )
                                            })}>
                                            <img
                                                src={item.icon_url}
                                                className='search-admin__dropdown-icon'
                                                alt={`Icon for searchbar's filter for ${item.name} category`} />
                                            {item.name}
                                            {!!item.children.length && !isMobile && (
                                                <Dropdown.Submenu position='right'>
                                                    {item.children.map((child) => (
                                                        <Dropdown.Item
                                                            key={child.id}
                                                            className={classNames({
                                                                'is-active':
                                                                    parseInt(categQuery) ===
                                                                    child.slug
                                                            })}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDropdownToggle();
                                                                handleCategorySearch(
                                                                    child.slug
                                                                );
                                                            }}>
                                                            <img
                                                                className='search-admin__dropdown-icon'
                                                                src={child.icon_url}
                                                                alt={`Icon for searchbar's filter for ${item.name} sub-category`} />
                                                            {child.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Submenu>
                                            )}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                            <FontAwesomeIcon
                                className='search-admin__icon'
                                icon={faSearch} />
                        </>}
                    <div ref={listRef} className={isMobile ? 'search__admin_mobile_input' : ''}>
                        {(pathname !== ROUTES.SEARCH.path) && <img src={SearchMobileIcon}
                            onClick={redirectToSearch} alt='Search' className='loginBtn__icon loginBtn__icon_menu_mobile' />}
                        {isMobile && <>
                            {pathname !== ROUTES.ORDER_CONFIRMATION
                                ? <Badge className='mobile__cart__badge' count={products?.length}>
                                    <img
                                        src={basket}
                                        alt='Icon for Shopping Cart'
                                        className={`loginBtn__icon mobile__cart__badge__img ${(token === '' || token === null || pathname === ROUTES.CART || pathname === ROUTES.HOME) ? 'normalized__version__cart__menu' : ''}`}
                                        onClick={goToCart} />
                                </Badge> : <img
                                    src={basket}
                                    alt='Icon for Shopping Cart'
                                    className={`loginBtn__icon mobile__cart__badge__img ${(token === '' || token === null || pathname === ROUTES.CART || pathname === ROUTES.HOME) ? 'normalized__version__cart__menu' : ''}`}
                                    onClick={goToCart} />}
                        </>}
                        {isMobile && <img src={mobileMenu}
                            onClick={showDrawer} alt='Button icon for link to Cart page' className='loginBtn__icon loginBtn__icon_menu_mobile' />}
                        {search &&
                            (isLoading ? (
                                <Spinner className='spinner search-admin__spinner search-admin__spinner_out_side_sheet' />
                            ) : (
                                <img
                                    className='search-admin__close'
                                    role='button'
                                    alt=''
                                    onClick={resetSearch}
                                    src={CloseIcon} />
                            ))}

                        {(!!productsList.length || !!categsList.length) &&
                            (isAutocompleteVisibile && !isMobile) && (
                            <Autocomplete
                                productsList={productsList}
                                categsList={categsList}
                                handleRedirection={handleRedirection}
                                propogateHideDrawer={hideDrawerOnItemSelection}
                                closeList={resetSearch} />
                        )}
                    </div>
                </FormGroup>
            </Form>
            <BottomSheet
                open={isSheetOpen}
                onDismiss={() => handleBottomSheetCloseHandler()}
                blocking={false}
                snapPoints={({ maxHeight }) => [maxHeight / 0.75]}>
                <div className='modalbox__close_btn_container'>
                    <div className='bottom__sheet_input_container'>
                        <Input
                            name='search'
                            value={search}
                            autoFocus={true}
                            autocomplete='off'
                            onClick={() => setAutocompleteVisibility(false)}
                            onChange={handleSearch}
                            onFocus={() => {
                                setIsLoading(false);

                                if (productsList.length > 0) {
                                    setAutocompleteVisibility(true);
                                }
                            }} />
                    </div>
                    <button onClick={() => {
                        setIsSheetOpen(false);
                        setAutocompleteVisibility(false);
                    }}>
                        <FontAwesomeIcon icon={faTimes} size='lg' />
                    </button>
                </div>
                {(isLoading && search) ? <div className='spinner__loading_mobile_container'>
                    <Spinner className='spinner search-admin__spinner' />
                </div> : <div className='search__modal_box_category_container'>
                    {(!!productCategories?.length && !search) && productCategories.map(c => <ExploreItem key={c.id} id={c.slug} title={c.name}
                        image={c.fmi_image?.url || NoImg} hideBottomSheet={handleBottomSheetCloseHandler} />)}
                    {search && ((isLoading && !search) && <Spinner className='spinner search-admin__spinner' />)}

                    {(!!productsList.length || !!categsList.length) &&
                        isAutocompleteVisibile && (
                        <Autocomplete
                            productsList={productsList}
                            categsList={categsList}
                            handleRedirection={handleRedirection}
                            propogateHideDrawer={hideDrawerOnItemSelection}
                            closeList={resetSearch} />
                    )}
                </div>}
            </BottomSheet>
            <SwipeableDrawer
                anchor={'left'}
                open={open}
                onClose={onClose}
                swipeAreaWidth={250}
                PaperProps={{
                    sx: { width: 250 }
                }}>
                <Box role='presentation' className='mobile__drawer'>
                    <div className='modal__header'>
                        <NavLink href={ROUTES.HOME}><img className='nav__logo' src={Logo} alt='Cinch Logo - Link to homepage' /></NavLink>
                    </div>
                    {(token !== '') && <Collapse accordion={true} ghost={true}
                        expandIconPosition='end'
                        className='drawer__menu'>
                        <UncontrolledAccordion defaultOpen='1'>
                            <AccordionItem>
                                <AccordionHeader targetId='1'>
                                    <p className='my_account_btn mb-0'>{'My Account'}</p>
                                </AccordionHeader>
                                <AccordionBody accordionId='1'>
                                    {(singpassStatus && singpassStatus !== 'rejected') &&
                                        <>
                                            <a href={ROUTES.MYINFORMATION} className={`nav-link ${pathname === ROUTES.MYINFORMATION ? 'selected__link__first' : ''}`} >{'My Information'}</a>
                                            <a href={ROUTES.SUBSCRIPTIONS} className={`nav-link ${pathname === ROUTES.SUBSCRIPTIONS ? 'selected__link' : ''}`}>{'Active Subscriptions'}</a>
                                            <a href={ROUTES.INACTIVE_SUBSCRIPTIONS} className={`nav-link ${pathname === ROUTES.INACTIVE_SUBSCRIPTIONS ? 'selected__link' : ''}`}>{'Inactive Subscriptions'}</a>
                                            <a href={ROUTES.ORDERS} className={`nav-link ${pathname === ROUTES.ORDERS ? 'selected__link' : ''}`}>{'Order in Progress'}</a>
                                            <a href={ROUTES.PAYMENTINFO} className={`nav-link ${pathname === ROUTES.PAYMENTINFO ? 'selected__link' : ''}`}>{'Payment Info'}</a>
                                            <a href={ROUTES.SHIPPINGINFO} className={`nav-link ${pathname === ROUTES.SHIPPINGINFO ? 'selected__link' : ''}`}>{'Shipping Info'}</a>
                                        </>
                                    }
                                    <a href={ROUTES.REFER_AND_EARN} className={`nav-link ${pathname === ROUTES.REFER_AND_EARN ? 'selected__link' : ''}`}>{'Refer and Earn'}</a>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                    </Collapse>}
                    <List type='unstyled'>
                        {token === '' && <p className='login__modal_link' onClick={() => {
                            loginModal(true);
                            setOpen(false);
                        }}>{'Login / Sign up'}</p>}
                        <li className='link__separator' />
                        <li><a href={ROUTES.PRODUCTS} className={`nav-link ${pathname === ROUTES.PRODUCTS ? 'selected__link' : ''}`}>{'All Products'}</a></li>
                        <li><a href={ROUTES.DEALS} className={`nav-link ${pathname === ROUTES.DEALS ? 'selected__link' : ''}`}>{'Deals'}</a></li>
                        <li><a href={ROUTES.FOR_BUSINESS} className={`nav-link ${pathname === ROUTES.FOR_BUSINESS ? 'selected__link' : ''}`}>{'For Businesses'}</a></li>
                        <li className='link__separator' />
                        {(productCategories && !isLoading) ? productCategories.map(c => <li className='products__listing_with_icons' key={c.id}><img src={c.icon_url} alt='icon'/><a href={`/products?category[]=${c.slug}&rental_period=12`} className={`nav-link ${categoryId === c.id ? 'selected__link' : ''}`}>{c.name}</a></li>) : <li>Loading...</li>}
                        <li className='link__separator' />
                        <li><a href={ROUTES.HOW_IT_WORKS} className={`nav-link ${pathname === ROUTES.HOW_IT_WORKS ? 'selected__link' : ''}`}>{'How it works'}</a></li>
                        <li><a href={ROUTES.FAQ} className={`nav-link ${pathname === ROUTES.FAQ ? 'selected__link' : ''}`}>{'FAQ'}</a></li>
                        <li><a href={ROUTES.PRIVACY_POLICY} className={`nav-link ${pathname === ROUTES.PRIVACY_POLICY ? 'selected__link' : ''}`}>{'Privacy Policy'}</a></li>
                        <li><a href={ROUTES.TERMS_AND_CONDITIONS} className={`nav-link ${pathname === ROUTES.TERMS_AND_CONDITIONS ? 'selected__link' : ''}`}>{'Terms and Conditions'}</a></li>
                        <li><a href={ROUTES.CONTACTUS} className={`nav-link ${pathname === ROUTES.CONTACTUS ? 'selected__link' : ''}`}>{'Contact Us'}</a></li>
                    </List>
                    {(token !== '' && singpassStatus && singpassStatus !== 'rejected') && <button className='mobile__logout_link' onClick={() => handleLogoutMobile()}>{'Logout'}</button>}
                    {(token !== '' && (!singpassStatus || singpassStatus === 'rejected')) &&
                        <>
                            <a href={ROUTES.MYINFORMATION} className='nav-link nav__btn login__modal_btn_get_started my__account-btn-sinpass-not-verified'>{'My Account'}</a>
                            <button className='mobile__logout_link' onClick={() => handleLogoutMobile()}>{'Logout'}</button>
                        </>}
                </Box>
            </SwipeableDrawer>
        </>);
};

const mapStateToProps = ({ auth }) => {
    const { token, profile } = auth;

    return {
        token,
        singpassStatus: profile.singpass_status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginModal: openLogin => dispatch(loginModal(openLogin)),
        handleLogout: () => dispatch(handleLogout())
    };
};

SearchAdmin.propTypes = {
    token: PropTypes.string,
    singpassStatus: PropTypes.any,
    loginModal: PropTypes.func.isRequired,
    handleOpenSignUp: PropTypes.func.isRequired,
    stateForBottomSheet: PropTypes.bool,
    productCategories: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAdmin);
