import { AUTH_API_URL, ELOQUENT } from 'config/constants';
import axios from 'axios';
import { notifType, notify } from 'utils/notifSender';
import { getAuthToken, getLastPageUrl } from 'utils/helpers';
import Cookies from 'js-cookie';

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export async function getAuthedProfile() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/me?checkoutValidationCheck=true'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('User not found.', notifType.DANGER);

        throw new Error(err);
    }
}

/**
 * Get OTP for Forgot Paswword
 * @param {String} code
 */
export async function getOTPForForgotPassword(code) {
    const options = {
        method: 'POST',
        params: { email: code },
        url: AUTH_API_URL + '/password/forgot'
    };

    await axios(options);
}

/**
 * Check OTP code
 * @param {String} code
 */
export async function checkOTPCode(code) {
    const options = {
        method: 'POST',
        params: { otp_code: code },
        url: AUTH_API_URL + '/password/reset/check'
    };

    try {
        await axios(options);
    } catch (err) {
        const status = err.response.status;

        if (status) {
            throw err;
        }
    }
}

/**
 * Reset Password
 * @param {Object} params
 */
export async function passwordReset(params) {
    const options = {
        method: 'POST',
        params,
        url: AUTH_API_URL + '/password/reset'
    };

    try {
        await axios(options);
    } catch (err) {
        const status = err.response.status;

        if (status) {
            throw err;
        }
    }
}

/**
 * Reset Password
 * @param {Object} params
 */
export async function userSimilarProducts() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/similar/products?limit=8'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        const status = err.response.status;

        if (status) {
            throw err;
        }
    }
}

/**
 * Create ticket
 * @param {Object} params
 */
export async function createTicket(params) {
    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        data: params,
        url: AUTH_API_URL + '/user/tickets/create'
    };

    try {
        await axios(options);
    } catch (err) {
        const status = err.response.status;

        if (status) {
            notify('Something went wrong. Please try again.', notifType.DANGER);

            throw err;
        }
    }
}

/**
 * Update a user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUserProfile(id, data) {
    return ELOQUENT.User.updateOne(id, data);
}

/**
 * fetches best rated sellers
 * @param {Object} data
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function fetchBestRatedSellers(data, cancelToken) {
    return ELOQUENT.User.bestRatedSellers(data, cancelToken);
}

/**
 * fetches best rated sellers
 * @param {Object} data
 * @param {Object} cancelToken
 * @returns {Promise}
 */
export function changePassword({ id, currentPassword, password }, cancelToken) {
    return ELOQUENT.User.updateOne(id, { current_password: currentPassword, password }, cancelToken);
}

/**
 * Store user events
 * @param {Object} params
 */
export async function storeUserEvents(params) {
    const userIP = await getUserIP();
    const authToken = getAuthToken();
    const headers = {};
    const options = {
        method: 'POST',
        params,
        headers,
        url: AUTH_API_URL + '/user/addevent'
    };

    params.user_ip = userIP;
    params.channel = 'cinch';
    params.referral = getLastPageUrl();
    params.gaSessionId = Cookies.get('_ga') || 'no-session';

    if (Cookies.get('_fp')) {
        params.exact_id = Cookies.get('_fp');
    }

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    try {
        await axios(options);
    } catch (err) {
        const status = err.response.status;

        if (status) {
            throw err;
        }
    }
}

export async function getUserIP() {
    try {
        const res = await axios.get('https://api.bigdatacloud.net/data/client-ip');

        return res.data.ipString;
    } catch (error) {
        return '';
    }
}

/**
 * Get logged in user's Pending Invoice data
 * @returns {Promise}
 */
export async function getPendingInvoice() {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + getAuthToken()
        },
        url: AUTH_API_URL + '/user/pending-invoice?checkoutValidationCheck=true'
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('User not found.', notifType.DANGER);

        throw new Error(err);
    }
}

/**
 * Get the user email availability check
 * @returns {Promise}
 */

export async function getUserEmailAvailability(email) {
    const options = {
        method: 'GET',
        url: AUTH_API_URL + `/auth/${email}/exists`
    };

    try {
        const { data } = await axios(options);

        return data;
    } catch (err) {
        notify('User not found.', notifType.DANGER);

        throw new Error(err);
    }
}
