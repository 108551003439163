import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { storeUserEvents } from 'api/user';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';

import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.png';

import style from './LoginForm.module.scss';

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string,
    setEmail: PropTypes.func.isRequired,
    backToMainLogin: PropTypes.func
};

function LoginForm({
    submit,
    changeForm,
    loading,
    email,
    setEmail,
    backToMainLogin
}) {
    const handleSubmit = e => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;

        if (email === '' || password === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        submit({ email, password, isRemember: true });
    };

    useEffect(() => {
        storeUserEvents({
            event: 'sign_in'
        });
    }, []);

    return (<div className={style.section}>
        <form onSubmit={handleSubmit}>
            <h3><img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />Sign In</h3>
            <p>Welcome ...</p>
            <div className={style.row}>
                <div className={style.label}>
                    Email Address
                </div>
                <div className={style.value}>
                    <input name='email' type='email' placeholder='Email Address'
                        className={style.value__input} value={email} onChange={e => setEmail(e.target.value)} />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.label}>
                    Password
                </div>
                <div className={style.value}>
                    <div className={style.withIcon}>
                        <input
                            name='password'
                            placeholder='Password'
                            className={style.value__input + ' ' + style.withIcon__input}
                            type='password' />
                    </div>
                </div>
            </div>
            <div className={style.forgot}>
                <span
                    className={style.forgot__title}
                    onClick={() => changeForm('forgot')}>Forgot Password?</span>
            </div>
            <div className={style.buttons}>
                <Button
                    loading={loading}
                    type='submit'
                    title={'Sign In'}
                    className={'submit'} />
            </div>
        </form>
    </div>);
}

export default LoginForm;
