import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { ROUTES } from 'config/constants';

import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { validateEmail } from 'utils/helpers';

import { storeUserEvents } from 'api/user';

import LoginScreenBackArrow from 'assets/images/login-screen-back-arrow.png';

import style from './SignUpForm.module.scss';

SignUpForm.propTypes = {
    submit: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string,
    backToMainLogin: PropTypes.func
};

function SignUpForm({
    submit,
    loading,
    email,
    setEmail,
    backToMainLogin
}) {
    const [checked, setChecked] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        const email = (e.target.email.value).replace(/ /g, '');
        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        if (!checked) return true;

        if (!validateEmail(email)) {
            notify('Please enter a valid email address. ', notifType.DANGER);

            return true;
        }

        if (password === '' || confirmPassword === '') {
            notify('Please fill in all required fields.', notifType.DANGER);

            return true;
        }

        if (password !== confirmPassword) {
            notify('Password and Confirm Password do not match.', notifType.DANGER);

            return true;
        }

        if (password.length < 5) {
            notify('Password is too short. Please choose another one.', notifType.DANGER);

            return true;
        }

        submit({
            email,
            password,
            confirmPassword
        });
    };

    useEffect(() => {
        storeUserEvents({
            event: 'sign_up'
        });
    }, []);

    const renderLabelComponent = () => <label>I agree to the <a href={ROUTES.TERMS_AND_CONDITIONS} target='_blank' rel='noreferrer'>Terms and condition</a></label>;

    const handleChange = (event) => setChecked(event.target.checked);

    return (<div className={`${style.section}  fs-exclude`}>
        <form onSubmit={handleSubmit}>
            <h3><img onClick={backToMainLogin} alt='Back arrow' src={LoginScreenBackArrow} />Sign up</h3>
            <p>Welcome ...</p>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Email Address
                    </div>
                    <div className={style.value}>
                        <input name='email' type='email' className={style.value__input}
                            placeholder='Email Address' value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='password'
                                className={style.value__input + ' ' + style.withIcon__input} placeholder='Password'
                                type='password' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Confirm Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='confirmPassword'
                                className={style.value__input + ' ' + style.withIcon__input} placeholder='Confirm Password'
                                type='password' />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={style.row}>
                <div className={style.row__fullCol}>
                    <div className={style.label}>
                        Confirm Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='confirmPassword'
                                className={style.value__input + ' ' + style.withIcon__input} placeholder='Confirm Password'
                                type='password' />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={style.row}>
                <div className={style.row__fullCol}>
                    <FormControlLabel control={<Checkbox onChange={handleChange} checked={checked} />} label={renderLabelComponent()} />
                </div>
            </div>
            <div className={style.buttons}>
                <Button
                    loading={loading}
                    type='submit'
                    title={'Sign Up'}
                    className={'submit'} />
            </div>
        </form>
    </div>);
}

export default SignUpForm;
