import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { APP_CURRENCY_NAME, ROUTES } from 'config/constants';
import { Button } from 'reactstrap';

import TradeInReqModal from 'components/TradeInReqModal/TradeInReqModal';
import SufficientBalance from 'layouts/CartLayout/SufficientBalance/SufficientBalance';

import { returnImgOfColor } from 'utils/helpers';

import NoImg from 'assets/images/noImg.png';
import VisaIcon from 'assets/images/general/visa-alt.png';
import MastercardIcon from 'assets/images/general/mastercard-alt.png';
import ParallelArrows from 'assets/images/general/parallel-arrows.svg';

import './Confirmation.scss';
import { storeUserEvents } from 'api/user';

const PaymentScreenConfirmation = ({
    cart,
    address,
    selectedPaymentMethod,
    meta,
    selectedTradeIn,
    currentOrderPayNowInfo
}) => {
    const { cardList, UserCart } = useSelector((state) => ({
        cardList: state.payment.cardList,
        UserCart: state.cart.fetchedProducts
    }));
    const [selectedCard, setSelectedCard] = useState('');
    const [isExtraMonthPromo, setIsExtraMonthPromo] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const productIds = UserCart?.map(e => e.base_product.id).toString();
    const cartId = UserCart[0]?.pivot.id;

    const onToggle = () => setIsOpen(!isOpen);

    const getProductStorageAttribute = (item) => {
        const find = item.base_product.attributes.find(e => e.type === 'storage');

        if (find) {
            const findAttribute = find.attribute_values.find(e => e.id === item.product_attribute_value_id);

            return (findAttribute && findAttribute.value) + ' | ';
        } else { return ''; }
    };

    useEffect(() => {
        const selectedCard = cardList.find(c => c.payment_method_id === selectedPaymentMethod);

        setSelectedCard(selectedCard);
    }, [cardList]);

    const formatNumber = (number) => {
        const num = Number(number);

        if (num % 1 === 0) {
            return num.toString();
        } else {
            return num.toFixed(2).toLocaleString('en-US');
        }
    };

    useEffect(() => {
        if (!productIds || !cartId) return;

        storeUserEvents({
            event: 'order_confirmation',
            product_id: productIds,
            order_id: '',
            cart_id: cartId
        });
    }, []);

    useEffect(() => {
        if (meta.coupon) {
            setIsExtraMonthPromo(meta.discount === '0.00');
        }
    }, [meta]);

    return (
        <>
            <div className='PaymentScreenConfirmation last-container'>
                <div className='PaymentScreenConfirmation__card'>
                    <div className='PaymentScreenConfirmation__card-content'>
                        <span className='PaymentScreenConfirmation__card__heading text-heading m-0 title'>Your order has been received</span>
                        <span className='PaymentScreenConfirmation__card__desc mb-3'>Your device will be delivered within 3-5 days upon confirmation.</span>
                    </div>
                    <di className='PaymentScreenConfirmation__card-content sufficient-balance'>
                        <SufficientBalance />
                    </di>
                    {cart.map((item) => (
                        <div key={item?.product_attribute_value_id} className='PaymentScreenConfirmation__card-content'>
                            <div className='PaymentScreenConfirmation__card__cart-item'>
                                <div className='PaymentScreenConfirmation__card__cart-item__image-container'>
                                    <img src={item.base_product?.medias.length !== 0 ? returnImgOfColor(item.color.id, item.base_product?.medias) : NoImg} alt={item?.base_product?.name} />
                                </div>
                                <div className='PaymentScreenConfirmation__card__cart-item__texts mt-2'>
                                    <div className='PaymentScreenConfirmation__card__cart-item__texts__name'>
                                        <span>{item?.base_product?.name}</span>
                                    </div>
                                    <span className='PaymentScreenConfirmation__card__cart-item__texts__spec'>{getProductStorageAttribute(item)}{item?.color?.name} | {APP_CURRENCY_NAME} {item?.total.toLocaleString('en-US')} / mo</span>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info'>
                                <span>Subscription Amount:</span>
                                <span className='value'><strong>{APP_CURRENCY_NAME} {Number(item?.total).toFixed(0).toLocaleString('en-US')} / mo</strong></span>
                            </div>
                            {/* {isExtraMonthPromo ? (
                                <>
                                    <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info PaymentScreenConfirmation__card__cart-item__texts__info__discount'>
                                        <span>Promo Benefit <i className='PaymentScreenConfirmation__card__cart-item__promo-code'>({meta.coupon})</i>:</span>
                                        <span className='value valuediscount'><strong>1 month free</strong></span>
                                    </div>
                                </>
                            ) : (null)} */}
                            <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info'>
                                <span>Subscription for</span>
                                <span className='value'>
                                    {
                                        isExtraMonthPromo
                                            ? `${item?.rental_period.duration_time + 1} month${(item?.rental_period.duration_time + 1) !== 1 ? 's' : ''}`
                                            : `${item?.rental_period.duration_time} month${item?.rental_period.duration_time !== 1 ? 's' : ''}`
                                    }
                                </span>
                            </div>
                        </div>
                    ))
                    }
                    {
                        meta?.discount > 0 && (
                            <div className='PaymentScreenConfirmation__card-content'>
                                &nbsp;
                                <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info'>
                                    <span>Subtotal:</span>
                                    <span className='value'><strong>{APP_CURRENCY_NAME} {formatNumber(meta?.subtotal)} / mo</strong></span>
                                </div>
                                <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info PaymentScreenConfirmation__card__cart-item__texts__info__discount'>
                                    <span>Discount <i className='PaymentScreenConfirmation__card__cart-item__promo-code'>({meta.coupon})</i>:</span>
                                    <span className='value valuediscount'><strong>- {APP_CURRENCY_NAME} {formatNumber(meta?.discount)} / mo</strong></span>
                                </div>
                                <div className='d-flex justify-content-between PaymentScreenConfirmation__card__cart-item__texts__info'>
                                    <span>Total Payable:</span>
                                    <span className='value'><strong>{APP_CURRENCY_NAME} {formatNumber(meta?.total)} / mo</strong></span>
                                </div>
                            </div>
                        )
                    }
                </div>
                {selectedTradeIn && <div className='PaymentScreenConfirmation__card PaymentScreenConfirmation__card-content trade-in-info'>
                    <div className='d-flex'>
                        <img src={ParallelArrows} alt='Trade in'
                            className='trade-in-info__arrows' />
                        <div className='d-flex justify-content-between'>
                            <span className='trade-in-info__info'>You have earned Cashback of <span className='price'>{selectedTradeIn?.cashback}</span>  through trade in of  <span className='price'>{selectedTradeIn?.series}</span></span>
                            <p className='trade-in-info__learn-more learn-more-mob' onClick={() => onToggle()}>Learn more</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between py-2'>
                        <p className='trade-in-info__note'>*Amount will be paid with <span className='strong'>5-7 days</span> through <span className='strong'>PayNow,</span> Upon Receiving & Inspection</p>
                        <p className='trade-in-info__learn-more' onClick={() => onToggle()}>Learn more</p>
                    </div>
                </div>}
                <div className='PaymentScreenConfirmation__card address-info fs-exclude'>
                    <div>
                        <span className='PaymentScreenConfirmation__card__heading text-sub-heading mb-3'>Shipping Addressss</span>
                        <span className='PaymentScreenConfirmation__card__cart-item__texts__spec'>{address?.name}</span>
                        <span className='PaymentScreenConfirmation__card__cart-item__texts__spec'>
                            {address?.block && <span>Block: {address.block},</span>}
                            {address?.street && <span> Street: {address.street}</span>}<br />
                            {address?.floor && <span> Floor: {address.floor},</span>}
                            {address?.unit && <span> Unit# {address.unit},</span>}
                            {address?.building && <span> Building: {address.building}</span>}<br />
                            {address?.country && <span>{address.country}</span>} {address?.postalCode && <span>{address.postalCode}</span>}
                        </span>
                        <span className='PaymentScreenConfirmation__card__cart-item__texts__spec'>{address?.mobile}</span>
                    </div>
                    <div className='payment-mode'>
                        <div>
                            <span className='PaymentScreenConfirmation__card__heading text-sub-heading mb-3'>Mode of Payment</span>
                            <span className='PaymentScreenConfirmation__card__cart-item__texts__spec my-auto payment__card__list'>
                                {selectedCard?.brand === 'visa' ? <img src={VisaIcon} alt='Visa Icon' /> : <img src={MastercardIcon} alt='Mastercard Icon' />}
                                **** **** **** {selectedCard?.last_four}
                            </span>
                        </div>
                        {selectedTradeIn && <div className='mt-3'>
                            <span className='PaymentScreenConfirmation__card__heading text-sub-heading mb-2'>Mode of Trade in Cashback</span>
                            <span className='PaymentScreenConfirmation__card__cart-item__texts__spec my-auto'>
                                {currentOrderPayNowInfo} (PayNow)
                            </span>
                        </div>}
                    </div>
                </div>
                <div className='PaymentScreenConfirmation__contents__buttons-container'>
                    <a href={ROUTES.ORDERS}>
                        <Button className='PaymentScreenConfirmation__contents__buttons-container__button'>View Order History</Button>
                    </a>
                    <a href={ROUTES.HOME}>
                        <Button className='PaymentScreenConfirmation__contents__buttons-container__button--outline' outline={true}>Go to Homepage</Button>
                    </a>
                </div>
            </div>
            <TradeInReqModal isOpen={isOpen} onToggle={onToggle} />
        </>
    );
};

PaymentScreenConfirmation.propTypes = {
    cart: PropTypes.array,
    address: PropTypes.object,
    selectedPaymentMethod: PropTypes.string,
    meta: PropTypes.array,
    selectedTradeIn: PropTypes.object,
    currentOrderPayNowInfo: PropTypes.object
};

export default PaymentScreenConfirmation;
