import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import * as FS from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { Sha1 } from '@aws-crypto/sha1-browser';
import { v4 as uuidv4 } from 'uuid';

import { CHATBOT_ROUTES } from 'config/constants';

import AppRouter from './components/Router/AppRouter';
import AppLoading from './components/Loading/AppLoading';
import { handleAutoLogin } from 'redux/reducers/auth';
import LoginModal from './components/LoginModal/LoginModal';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Bell from './components/Bell/Bell';
import UtmParamsChecker from 'components/UtmParamsHandler';
import ForBusinessClientsContainer from 'containers/ForBusinessClients/ForBusinessClientsContainer';
import ErrorBoundary from './DefaultErrorBoundary';

import { trackFreshChatUser, trackKlaviyoEvent } from 'api/tracking';
import { getAuthedProfile } from 'api/user';

import { createWebHistoryStack, clearWebHistoryStack } from 'utils/helpers';

import 'react-notifications-component/dist/theme.css';
import './AppContainer.scss';
import ImeiLookup from 'containers/ImeiLookup/ImeiLookup';

const AppContainer = ({
    location,
    autoLogin,
    isAppReady,
    isAuthed,
    openLogin,
    profile
}) => {
    const hash = new Sha1();
    const [isIdle, setIsIdle] = useState(false);
    var idleTimer;

    useEffect(() => {
        clearWebHistoryStack();
        autoLogin();
    }, [autoLogin]);

    useEffect(() => {
        ReactGA.initialize('G-3YZYGR3XWT');
        ReactGA.gtag('config', 'GTM-NRZ5357');
        TiktokPixel.init('CDTDC8RC77U48G2OG19G');
        TiktokPixel.pageView();
    }, []);

    useEffect(() => {
        const { pathname } = location;

        if (pathname === '/business') {
            return;
        }

        const data = { hitType: 'pageview', page: pathname };

        if (profile.id) {
            data.userID = profile.id;
            data.userEmail = profile.email;

            if (FS) {
                FS.identify(data.userID.toString(), {
                    displayName: data.userID.toString()
                });
            }

            trackKlaviyoEvent('identify', data);
            trackFreshChatUser('identify', profile);

            const _hsq = window._hsq = window._hsq || [];

            if (_hsq) {
                _hsq.push(['identify', {
                    email: data.email,
                    id: data.userEmail
                }]);
            }
        }

        ReactGA.send(data);

        const _hsq = window._hsq = window._hsq || [];

        _hsq.push(['setPath', pathname]);

        createWebHistoryStack(location);
    }, [location, profile]);

    useEffect(() => {
        let uuid = null;

        if (!localStorage.getItem('uuid')) {
            uuid = uuidv4();
            localStorage.setItem('uuid', uuid);
        } else {
            uuid = localStorage.getItem('uuid');
        }

        if (isAuthed) {
            getAuthedProfile().then(async (res) => {
                hash.update(res?.data?.email);
                const result = await hash.digest();

                window.ire('identify', {
                    customerId: res?.data?.id,
                    customerEmail: result,
                    customProfileId: uuid
                });
            });
        } else {
            window.ire('identify', {
                customerId: '',
                customerEmail: '',
                customProfileId: uuid
            });
        }

        overrideChatWidgetStyle(location?.pathname);
    }, [isAuthed, location]);

    const handleActivity = () => {
        clearTimeout(idleTimer);

        if (isIdle) {
            setIsIdle(true);
        }

        setIsIdle(false);

        idleTimer = setTimeout(() => {
            setIsIdle(true);
        }, 15000);
    };

    const onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });
    };

    useEffect(() => {
        document.addEventListener('click', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('keyup', handleActivity);
        document.addEventListener('touchstart', handleActivity);

        idleTimer = setTimeout(() => {
            setIsIdle(true);
        }, 15000);

        return () => {
            document.removeEventListener('click', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            document.removeEventListener('keyup', handleActivity);
            document.removeEventListener('touchstart', handleActivity);
        };
    }, []);

    const overrideChatWidgetStyle = (path) => {
        var style = document.createElement('style');

        style.type = 'text/css';

        var cssRule = '#fc_widget { display: none !important; }';

        if (CHATBOT_ROUTES.includes(path)) {
            cssRule = '#fc_widget { display: block !important; }';
        }

        if (path.indexOf('product') > -1) {
            cssRule = '#fc_widget { display: block !important; }';
        }

        if (style.styleSheet) {
            style.styleSheet.cssText = cssRule;
        } else {
            style.appendChild(document.createTextNode(cssRule));
        }

        const head = document.getElementsByTagName('head')[0];
        const existingStyle = document.getElementById('chatWidgetStyle');

        if (existingStyle) {
            head.removeChild(existingStyle);
        }

        style.id = 'chatWidgetStyle';

        // var script = document.createElement('script');

        // script.src = '//fw-cdn.com/7754048/3276140.js';
        // script.widgetId = '0c9525ac-bb16-4ceb-b8e9-f0ccbf7f0d9b';
        // script.chat = 'true';
        // document.head.appendChild(script);

        head.appendChild(style);
    };

    return (
        <main className='AppContainer' id='mainAppContainer'>
            {['/business', '/imei-lookup'].includes(location.pathname) ? (
                <>
                    {location.pathname === '/business' && <ForBusinessClientsContainer />}
                    {location.pathname === '/imei-lookup' && <ImeiLookup />}
                </>
            ) : (
                <>
                    {isAuthed && !profile.singpass_status && <Bell />}
                    <LoginModal isOpen={openLogin} />
                    <ReactNotifications className='pt-notifs' />
                    <ErrorBoundary pathname={location.pathname} onError={onError}>
                        <>
                            <ScrollToTop />
                            <AppRouter isAuthed={isAuthed} />
                            <UtmParamsChecker isAuthed={isAuthed} />
                        </>
                    </ErrorBoundary>
                </>
            )}
            {isAppReady ? null : <AppLoading />}
        </main>
    );
};

AppContainer.propTypes = {
    location: PropTypes.object.isRequired,
    autoLogin: PropTypes.func.isRequired,
    isAppReady: PropTypes.bool.isRequired,
    isAuthed: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    openLogin: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth, i18n }) => {
    const { isAppReady, isAuthed, openLogin, profile } = auth;
    const { language } = i18n;

    return {
        isAppReady,
        isAuthed,
        language,
        openLogin,
        profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoLogin: (location) => dispatch(handleAutoLogin(location))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
