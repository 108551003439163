import React, { useEffect, useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { StringParam, useQueryParam } from 'use-query-params';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'config/constants';

import { getRentalsMinMaxValues } from 'api/products';

import { useFixedContent } from 'utils/helpers';

import Sidebar from 'containers/App/components/Sidebar/Sidebar';
import Header from 'containers/App/components/Header/Header';
import Footer from 'containers/App/components/Footer/Footer';

import 'react-spring-bottom-sheet/dist/style.css';
// import PromoBanner from '../Header/PromoBanner/PromoBanner';

const Layout = ({ children, stateForBottomSheet, productCategories }) => {
    const location = useLocation();
    const [priceLimits] = useState([0, 0]);
    const isMobile = window.matchMedia('(max-width: 992px)').matches;
    const [isSheetOpenForSorting, setIsSheetOpenForSorting] = useState(false);
    const [isSheetOpenForFiltering, setIsSheetOpenForFiltering] = useState(false);
    const [periodQuery] = useQueryParam(
        'rental_period',
        StringParam
    );
    const [sidebarIsOpen, setSidebarOpen] = useState(false);
    const isInvitedPage = location.pathname === '/invite';

    useFixedContent();

    useEffect(() => {
        if (isSheetOpenForSorting || isSheetOpenForFiltering) getRentalsMinMaxValues(periodQuery || 12);
    }, [isSheetOpenForSorting, isSheetOpenForFiltering]);

    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

    const handleBottomSheetCloseHandler = () => {
        if (isSheetOpenForSorting) setIsSheetOpenForSorting(false);
        if (isSheetOpenForFiltering) setIsSheetOpenForFiltering(false);
    };

    return (
        <div className='layout'>
            <div className='--fixed-content'>
                <Header productCategories={productCategories} stateForBottomSheet={stateForBottomSheet} />
            </div>
            {/* <PromoBanner /> */}
            <div className={`elfsight-app-c420cecf-1dd6-4e42-84af-8cf6d8427fbd ${(
                location.pathname !== ROUTES.CHECKOUT &&
                location.pathname !== ROUTES.CART &&
                location.pathname !== ROUTES.SINGPASS &&
                location.pathname !== ROUTES.SINGPASS_MANUALL_FORM &&
                location.pathname !== ROUTES.SINGPASS_RESULT &&
                location.pathname !== ROUTES.SHIPPING &&
                location.pathname !== ROUTES.PAYMENT &&
                location.pathname !== ROUTES.ORDER_SUMMARY &&
                location.pathname !== ROUTES.ORDER_SUMMARY_CONFIRMATION
            ) ? 'show' : 'hide'}`} data-elfsight-app-lazy={true} />
            {/* <PromoBanner /> */}
            {Children.map(children, child => cloneElement(child, { productCategories: productCategories }))}
            {isMobile && <BottomSheet
                open={isSheetOpenForFiltering || isSheetOpenForSorting}
                onDismiss={() => handleBottomSheetCloseHandler()}
                blocking={false}
                snapPoints={({ maxHeight }) => isSheetOpenForSorting ? [maxHeight / 1.75] : [maxHeight / 1.001]}>
                <div className='modalbox__close_btn_container'>
                    <button onClick={() => {
                        setIsSheetOpenForFiltering(false);
                        setIsSheetOpenForSorting(false);
                    }}><FontAwesomeIcon icon={faTimes} size='lg' /></button>
                </div>
                <Sidebar
                    toggle={toggleSidebar}
                    isOpen={sidebarIsOpen}
                    isSheetOpenForSorting={isSheetOpenForSorting}
                    isSheetOpenForFiltering={isSheetOpenForFiltering}
                    priceLimits={priceLimits} />
            </BottomSheet>}
            {!isInvitedPage && <Footer />}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.any.isRequired,
    stateForBottomSheet: PropTypes.bool,
    productCategories: PropTypes.array
};

export default Layout;
