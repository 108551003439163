import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { $, add } from 'moneysafe';
import * as FS from '@fullstory/browser';
import { trackKlaviyoEvent } from 'api/tracking';
import { Badge, Modal } from 'reactstrap';

import Feature from '../Features/Feature';
import RentalPeriod from '../RentalPeriod/RentalPeriod';
import Button from 'containers/App/components/Button/Button';
import { loginModal } from 'redux/reducers/auth';
import Cart from '../Cart/Cart';

import { addProductToCart, clearCart } from 'redux/reducers/cart';
import { notifType, notify } from 'utils/notifSender';
import { addItemsToCart, getUserCart } from 'api/cart';
import { ROUTES } from 'config/constants';
import FormMessage from '../FormMessage';
import TradeIn from '../TradeIn/TradeIn';

import truck from 'assets/images/general/truck.svg';
import umbrella from 'assets/images/general/umbrella.svg';
import basket from 'assets/images/general/basket.svg';
import ShoppingCartWhite from 'assets/images/general/cart-outline-white.svg';
import _ from 'lodash';

import './DealsBeforeBuy.scss';

import ColorOptionsMobileView from '../ColorOptionsMobileView/ColorOptionsMobileView';
import StorageOptionsMobieView from '../StorageOptionsMobieView/StorageOptionsMobieView';

import style from './DealsBeforeBuy.module.scss';
import CommitmentAndInsideBox from '../CommitmentAndInsideBox/CommitmentAndInsideBox';

DealsBeforeBuy.propTypes = {
    title: PropTypes.string.isRequired,
    // price: PropTypes.number,
    priceDetails: PropTypes.string.isRequired,
    loginModal: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired,
    rentalOptions: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    clearCart: PropTypes.func.isRequired,
    singpassStatus: PropTypes.string,
    attribute: PropTypes.array,
    selectedRentalPeriod: PropTypes.object,
    setSelectedRentalPeriod: PropTypes.func,
    selectedColor: PropTypes.object,
    setSelectedColor: PropTypes.func,
    selectedStorage: PropTypes.object,
    setSelectedStorage: PropTypes.func,
    images: PropTypes.array,
    selectedDeals: PropTypes.object,
    deals: PropTypes.array,
    colorsGallery: PropTypes.array
};

function DealsBeforeBuy({
    title,
    // price,
    priceDetails,
    loginModal,
    currency,
    colors,
    token,
    rentalOptions,
    addProductToCart,
    product,
    // clearCart,
    singpassStatus,
    attribute,
    selectedRentalPeriod,
    setSelectedRentalPeriod,
    selectedColor,
    setSelectedColor,
    selectedStorage,
    setSelectedStorage,
    images,
    selectedDeals,
    colorsGallery,
    deals
}) {
    const [openCart, setOpenCart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [cartMeta, setCartMeta] = useState({});
    const [flag, setFlag] = useState(false);
    const [addToCartFlag, setAddToCartFlag] = useState(false);
    const [isTradeInApplied, setIsTradeInApplied] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (openCart) {
            // get cart items from BE
            const getData = async () => {
                setLoading(true);

                try {
                    const { data, meta } = await getUserCart();

                    setCartItems(data.products);
                    setCartMeta(meta);
                } catch (error) {
                    //
                }

                setLoading(false);
            };

            getData();
        } else {
            trackKlaviyoEvent('product-view', { title: title, product: product, images: images[0].images, price: getPrice(), deals: true });
        }
    }, [openCart, flag]);

    useEffect(() => {
        if (addToCartFlag) {
            trackKlaviyoEvent('add-cart', { title: title, product: product, images: images[0].images, price: getPrice(), cartItems: cartItems, cartMeta: cartMeta, deals: true });
        }
    }, [cartItems, flag]);

    const reFetchCart = () => { setFlag(!flag); };

    const getPrice = (discount) => {
        let price = $(selectedRentalPeriod.price);

        if (selectedStorage && selectedStorage.item.price_impact) {
            const find = selectedStorage.item.price_impacts.find(e => e.rental_period_id === selectedRentalPeriod.id);

            if (find) {
                price = add($(price), $(find.price_impacts));
            }
        }

        if (discount && selectedDeals?.discount_type === 'percentage') {
            price = Math.ceil($(price) - ($(price) * discount) / 100).toFixed(0);
        } else if (discount) {
            price = Math.ceil($(price) - $(discount)).toFixed(0);
        }

        return $(price).toString();
    };

    const handleRental = i => { setSelectedRentalPeriod(i); };

    const handleAddToCart = async () => {
        setAddToCartFlag(true);

        if (selectedRentalPeriod.id === -1) {
            notify('Please select a rental option.', notifType.DANGER);

            return true;
        }

        if (colors.length !== 0 && !selectedColor) {
            notify('Please select a color.', notifType.DANGER);

            return true;
        }

        if ((attribute && attribute.length !== 0 && Object.prototype.hasOwnProperty.call(attribute, 'attribute_storage'))) {
            if (!selectedStorage) {
                notify('Please select a storage option.', notifType.DANGER);

                return true;
            }
        }

        // for show login modal
        if (token === '') {
            // add to redux and open login modal
            loginModal(true);

            addProductToCart({
                ...product,
                images,
                selectedRentalPeriod,
                selectedColor,
                selectedStorage,
                selectedDeals
            });

            FS.event('Product Added To Cart', {
                product_id: product.id.toString(),
                product_name: product.name,
                product_attribute_value_id: selectedStorage ? selectedStorage.value
                    : (
                        product.attributes.specifications.values[0].value ||
                        product.attributes.inside_box.values[0].value
                    ),
                quantity: '1',
                rental_period: selectedRentalPeriod.duration_time,
                color_id: selectedColor.value
            });

            const baseProduct = { ...product };

            baseProduct.total = getPrice();
            baseProduct.medias = images[0].images;

            trackKlaviyoEvent('add-cart', { title: title, product: product, images: images[0].images, price: getPrice(), cartItems: [{ base_product: baseProduct }], cartMeta: { price: getPrice() }, deals: true });
        } else {
            // submit order and clear cart state in redux
            const makeProducts = [];
            const keys = Object.keys(product.attributes);

            if ((product.attributes && keys.length > 0 && keys.includes('storage'))) {
                const storageAttributes = product?.attributes?.storage?.values;

                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: selectedStorage.value || storageAttributes[0]?.id,
                        quantity: 1,
                        rental_period_id: selectedRentalPeriod.id,
                        product_id: product.id,
                        color_id: selectedColor.value,
                        deal_id: selectedDeals && !selectedDeals.discount_type ? selectedDeals.id : null,
                        flash_deal_product_id: selectedDeals && selectedDeals.discount_type ? selectedDeals.id : null
                    }
                );
            } else {
                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: (product.attributes.specifications.values[0].id || product.attributes.inside_box.values[0].id),
                        quantity: 1,
                        rental_period_id: selectedRentalPeriod.id,
                        product_id: product.id,
                        color_id: selectedColor.value,
                        deal_id: selectedDeals && !selectedDeals.discount_type ? selectedDeals.id : null,
                        flash_deal_product_id: selectedDeals && selectedDeals.discount_type ? selectedDeals.id : null
                    }
                );
            }

            setLoading(true);

            try {
                await addItemsToCart(makeProducts);

                notify('Product added to cart.', notifType.SUCCESS);

                setOpenCart(true);

                if ((product.attributes && keys.length > 0 && keys.includes('storage'))) {
                    const storageAttributes = product?.attributes?.storage?.values;

                    FS.event('Product Added To Cart', {
                        product_id: product.id.toString(),
                        product_name: product.name,
                        product_attribute_value_id: selectedStorage.value || storageAttributes[0]?.id,
                        quantity: '1',
                        rental_period: selectedRentalPeriod.duration_time,
                        color_id: selectedColor.value
                    });
                } else {
                    FS.event('Product Added To Cart', {
                        product_id: product.id.toString(),
                        product_name: product.name,
                        product_attribute_value_id: (product.attributes.specifications.values[0].id || product.attributes.inside_box.values[0].id),
                        quantity: '1',
                        rental_period: selectedRentalPeriod.duration_time,
                        color_id: selectedColor.value
                    });
                }
            } catch (err) {
                // ToDo
            }

            setLoading(false);
        }
    };

    const submitCart = async () => {
        // if user is otp_required then should complete the Singpass info
        if (!singpassStatus || singpassStatus !== 'verified') {
            notify('Complete your profile first.', notifType.DANGER);

            history.push(ROUTES.MYINFORMATION);

            return;
        }

        // if user is verified can proced the order
        if (singpassStatus === 'verified') {
            history.push(ROUTES.CART);
        }
    };

    const handleColor = e => {
        setSelectedColor(e);
    };

    const handleStorage = e => {
        setSelectedStorage(e);
    };

    const getDealColor = () => {
        const dealColor = colors.find(e => e.value === Number(selectedDeals.asset.color_id));

        handleColor(dealColor);

        return [dealColor];
    };

    const isInStock = (selectedColor) => {
        /* eslint-disable camelcase */
        const internalAsset = product?.internal_asset_codes?.find(({ product_attribute_value_id, color_id }) => (
            (product_attribute_value_id ? product_attribute_value_id === selectedStorage?.value : true) &&
            color_id === selectedColor?.value
        ));

        return internalAsset?.in_stock === 1;
    };

    const getDealStorage = () => {
        let dealStorage;

        if (selectedDeals.asset.product_attribute_value_id) {
            dealStorage = attribute.find(e => e.item && e.item.key === 'attribute_storage' && e.item.id === selectedDeals.asset.product_attribute_value_id);
        } else {
            dealStorage = attribute.find(e => e.item && e.item.key === 'attribute_storage');
        }

        handleStorage(dealStorage);

        return [dealStorage];
    };

    const getDiscountPercent = (price, discount) => {
        if (selectedDeals?.discount_type === 'percentage') {
            return discount;
        }

        const discountPercent = Math.ceil((discount) * 100 / price);

        return discountPercent.toString();
    };

    return (<div className={style.BeforeBuy}>
        <div className={style.header}>
            <h1 className={style.title}>{title}</h1>
            <Badge
                key={selectedDeals.id}
                pill={true} color='danger'>
                -{getDiscountPercent(selectedRentalPeriod.price, selectedDeals.discount_amount)}%
            </Badge>
        </div>
        {rentalOptions.length !== 0 &&
            <p className={`${style.price} mb-0`}>
                <span className={style.price__value}>
                    <span className='price-strike'>
                        {currency} {getPrice()}
                    </span>
                    <span className='discount-price'>{currency} {getPrice(selectedDeals.discount_amount)}</span></span>
                {/* {priceDetails} */}/ mo
            </p>
        }

        {/* <div>
            {features.map((feature, i) =>
                <Feature key={i} {...feature}/>
            )}
        </div> */}
        {(attribute && attribute.length > 0 && attribute.some(e => e.item && e.item.key === 'attribute_storage')) &&
            <div className='storage__section-section '>
                <StorageOptionsMobieView
                    options={getDealStorage()}
                    colors={colors}
                    selectedStorage={getDealStorage()[0]}
                    onClick={handleStorage} />
            </div>}

        {getDealColor().length !== 0 &&
            <div className={style.section}>
                <ColorOptionsMobileView
                    container='deals'
                    options={getDealColor()}
                    selectedColor={getDealColor()[0]}
                    colorsGallery={colorsGallery}
                    onClick={handleColor} />

            </div>}

        {rentalOptions.length !== 0 &&
            <div className='rental__period-section'>
                <RentalPeriod
                    rentalOptions={rentalOptions}
                    selected={selectedRentalPeriod}
                    isSelected={false}
                    onClick={handleRental} />
            </div>
        }

        {product?.category?.name === 'Smartphones' &&
            <div className='trade-ins'>
                <TradeIn setIsTradeInApplied={setIsTradeInApplied} />
            </div>}

        {/* {rentalOptions.length !== 0 &&
            <div>
                <Button
                    loading={loading}
                    onClick={handleAddToCart}
                    title={<span
                        className={style.addTocart__title}>
                        <img
                            className={style.addTocart__icon}
                            src={basketWhite}
                            alt='Add to cart icon' />
                        Add to cart
                    </span>}
                    className={style.addTocart} />
            </div>
        } */}

        <CommitmentAndInsideBox insideBoxItems={product?.attributes?.inside_box?.values} />

        {(rentalOptions.length !== 0) &&
            <div className='desktop-add-to-cart-section-container  container-fluid'>
                <div className='desktop-add-to-cart-section'>
                    <div className='desktop-add-to-cart-section__content d-flex'>
                        <div className='title-container'>
                            <p className='title d-flex justify-content-between align-items-center'>
                                {_.truncate(title, { length: 50, omission: '...' })}&nbsp;
                                <span className='desc ml-3'>{selectedStorage?.label} {selectedColor?.text && selectedStorage?.label ? ' | ' : ' '} {selectedColor?.text}</span>
                            </p>
                            <p className='duration justify-content-between mb-0'>
                                <span className='duration__content mr-3'>For&nbsp;<span className='duration__value'>{selectedRentalPeriod?.duration_time} Month{selectedRentalPeriod.duration_time > 1 && 's'}</span></span>
                                <span className={`${isTradeInApplied ? 'duration__content' : ''} duration__value`}>FREE Damage Cover</span>
                                {isTradeInApplied && <span className='trade-in-applied trade-in-applied__mobile'>Trade-In Applied</span>}
                            </p>
                        </div>
                        {isTradeInApplied && <span className='trade-in-applied trade-in-applied__desktop'>Trade-In Applied</span>}
                    </div>
                    <div className='desktop-add-to-cart-section__button-container'>
                        {/* <div className='ribbon'>
                            <span className='ribbon-text'>Only 2 Left</span>
                            <span className='ribbon-fold-left' />
                        </div> */}
                        <Button
                            className='desktop-add-to-cart-section__button-container__button'
                            loading={loading}
                            onClick={handleAddToCart}
                            disabled={!isInStock(selectedColor)}
                            title={<span className='d-flex align-items-center'>
                                <span className='price'>{currency} {getPrice(selectedDeals.discount_amount)}</span><span className='month'>&nbsp;/mo</span>
                                <img className='desktop-add-to-cart-section__button-container__cart-icon'
                                    src={ShoppingCartWhite} alt='Add to cart' />
                            </span>} />
                    </div>
                </div>
            </div>}

        <Cart
            cartMeta={cartMeta}
            reFetchCart={reFetchCart}
            cartItems={cartItems}
            loading={loading}
            submitCart={submitCart}
            isOpen={openCart}
            toggle={() => setOpenCart(false)} />

        <Modal
            onClosed={() => setOpenModal(false)}
            className={style.modal}
            centered={true}
            isOpen={openModal}>
            <FormMessage />
        </Modal>
    </div>);
}

function mapDispatchToProps(dispatch) {
    return {
        loginModal: (openLogin) => {
            dispatch(loginModal(openLogin));
        },
        addProductToCart: (product) => {
            dispatch(addProductToCart(product));
        },
        clearCart: () => {
            dispatch(clearCart());
        }
    };
}

export default connect(null, mapDispatchToProps)(DealsBeforeBuy);

const features = [
    { icon: umbrella, title: 'Free', detail: 'Cinch Insurance' },
    { icon: basket, title: 'Includes', detail: 'purchase option' },
    { icon: truck, title: '', detail: 'Delivery in 3-5 business days' }
];
