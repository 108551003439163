/* eslint-disable import/no-anonymous-default-export */
// import { replace } from 'connected-react-router';
import { register, login, logout, loginWithOTP, getOTPForLogin } from 'api/auth';
import { getAuthedProfile, updateUserProfile, getPendingInvoice } from 'api/user';
import { ROUTES, STORAGEKEY_PASSWORD_SECRET_KEY } from 'config/constants';
import { setAuthToken, getAuthToken, deleteAuthToken, createAxiosInstance, getReferralParam, deleteReferralParam, deleteTradeInInfo, getNavigatorData } from 'utils/helpers';
import { notifType, notify } from 'utils/notifSender';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { trackKlaviyoEvent } from 'api/tracking';
import CryptoJS from 'crypto-js';
// import {
//     ROUTE_REDIRECT_AFTER_LOGIN,
//     ROUTE_REDIRECT_AFTER_REGISTRATION
// } from 'config/constants';

const IS_APP_READY = 'IS_APP_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const OPEN_LOGIN = 'OPEN_LOGIN';
const SHOW_OTP = 'SHOW_OTP';
const HIDE_OTP = 'HIDE_OTP';
const OPEN_SIGNUP = 'OPEN_SIGNUP';
const CLOSE_SIGNUP = 'CLOSE_SIGNUP';
const SHOW_SUBMIT = 'SHOW_SUBMIT';
const NOA = 'NOA';
const ENABLESINGPASS = 'ENABLESINGPASS';

const UPDATE_SINGPASS_SUBMISSION_KEY = 'UPDATE_SINGPASS_SUBMISSION_KEY';
// const SET_TOKEN = 'SET_TOKEN';

export const appIsReady = () => ({
    type: IS_APP_READY
});

const authStart = () => ({
    type: AUTH_START
});

const authSuccess = (profile, token, userDoLoggedin, invited) => ({
    type: AUTH_SUCCESS,
    profile,
    token,
    userDoLoggedin,
    invited
});

const loginError = () => ({
    type: AUTH_FAILED_LOGIN
});

const registrationError = () => ({
    type: AUTH_FAILED_REGISTRATION
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

export const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

const showOTPForm = () => ({
    type: SHOW_OTP
});

export const loginModal = (openLogin) => ({
    type: OPEN_LOGIN,
    openLogin
});

export const handleHideOTP = () => ({
    type: HIDE_OTP
});

export const updateSingpassSubmissionKey = (submissionKey) => ({
    type: UPDATE_SINGPASS_SUBMISSION_KEY,
    payload: submissionKey
});

export const showSubmit = payload => ({
    type: SHOW_SUBMIT,
    payload
});

export const handleNOA = payload => ({
    type: NOA,
    payload
});

export const handleEnableSingpass = () => ({
    type: ENABLESINGPASS
});

const TOGGLE_INVOICE_MODAL = 'TOGGLE_INVOICE_MODAL';

export const toggleInvoiceModal = (isOpen, orderId = '', invoiceUrl = '') => ({
    type: TOGGLE_INVOICE_MODAL,
    isOpen,
    orderId,
    invoiceUrl
});

export function handleGetOTPForLogin({ phone }) {
    return async (dispatch) => {
        await dispatch(authStart());

        try {
            await getOTPForLogin({ mobile_number: phone });
            notify('OTP code sent to your email.', notifType.SUCCESS);
            await dispatch(showOTPForm());
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleLoginWithOTP({ otp }) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            const token = await loginWithOTP({ login_otp: otp });

            setAuthToken(token);

            const data = await getAuthedProfile();

            notify('Welcome ' + data.name + ' ' + data.surname, notifType.SUCCESS);

            dispatch(authSuccess(data.data, token, false, data.invited));
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleLogin({ email, password, isRemember, gaSessionId, trueIp, onSuccess }) {
    return async (dispatch) => {
        dispatch(authStart());

        const secretKey = STORAGEKEY_PASSWORD_SECRET_KEY;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedPassword = encrypted.toString();
        const encryptedIv = CryptoJS.enc.Base64.stringify(iv);

        const tmp = {
            email,
            password: encryptedPassword,
            iv: encryptedIv,
            ga_session_id: gaSessionId,
            true_ip: trueIp,
            navigator: getNavigatorData()
        };

        if (Cookies.get('_fp')) {
            // if we have fp send it to BE
            tmp.exact_id = Cookies.get('_fp');
        }

        try {
            const resp = await login(tmp);

            if (!Cookies.get('_fp')) {
                // if user doesn't use this device for signup add fingerprint to its cookie
                Cookies.set('_fp', resp.fingerprint);
            }

            // setAuthToken(token, isRemember);
            setAuthToken(resp.access_token);

            const data = await getAuthedProfile();

            let pendingInvoice = null;

            if (data.data.activeSubscriber) {
                pendingInvoice = await getPendingInvoice();
            }

            notify('Welcome!', notifType.SUCCESS);

            await dispatch(authSuccess(data.data, resp.access_token, true, data.invited));
            onSuccess(pendingInvoice ? pendingInvoice.data : {});
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleRegistration({
    // name,
    // surname,
    // phone,
    email,
    password,
    confirmPassword,
    gaSessionId,
    trueIp,
    needRedirect,
    onSuccess
}) {
    return async (dispatch) => {
        dispatch(authStart());

        const storageReferral = getReferralParam();

        const secretKey = STORAGEKEY_PASSWORD_SECRET_KEY;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(128 / 8);
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedPassword = encrypted.toString();
        const encryptedConfirm = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(confirmPassword), key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const encryptedConfirmPassword = encryptedConfirm.toString();
        const encryptedIv = CryptoJS.enc.Base64.stringify(iv);

        const tmp = {
            // name,
            // surname,
            // mobile_number: phone,
            email,
            password: encryptedPassword,
            password_confirmation: encryptedConfirmPassword,
            iv: encryptedIv,
            ga_session_id: gaSessionId,
            true_ip: trueIp,
            referral_code: storageReferral,
            navigator: getNavigatorData()
        };

        if (Cookies.get('_fp')) {
            // if we have fp send it to BE
            tmp.exact_id = Cookies.get('_fp');
        }

        try {
            const user = await register(tmp);

            const token = user.access_token;

            if (!Cookies.get('_fp')) {
                // if user doesn't use this device before add fingerprint to its cookie
                Cookies.set('_fp', user.fingerprint);
            }

            deleteReferralParam();

            onSuccess();

            setAuthToken(token);

            const data = await getAuthedProfile();

            // post to HubSpot to populate Contacts table
            const hubspotWebsiteFormUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/23260945/aaa48c80-431f-43fa-a617-9687fc097720';
            const hubspotFormFields = [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: email
                }
            ];

            const context = {
                hutk: Cookies.get('hubspotutk'),
                pageUri: window.location.href,
                pageName: document.title,
                ipAddress: trueIp
            };

            await axios.post(hubspotWebsiteFormUrl, {
                fields: hubspotFormFields,
                context: context
            }).then(() => { }).catch(() => { });

            const trackPayload = {
                ...data.data
            };

            trackPayload.referral_code = storageReferral;
            trackKlaviyoEvent('user-registered', trackPayload);

            notify('Welcome!', notifType.SUCCESS);

            dispatch(authSuccess(data.data, token, true, data.invited));

            if (needRedirect) {
                window.location.replace(ROUTES.HOME);
            }
        } catch (err) {
            dispatch(registrationError());
        }
    };
}

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                const data = await getAuthedProfile(token);

                ReactGA.gtag('set', 'user_id', data.id);

                dispatch(authSuccess(data.data, token, true, data.invited));
            }
        } catch (err) {
            dispatch(handleLogout(true));
        } finally {
            dispatch(appIsReady());
        }
    };
}

export function handleLogout() {
    return async (dispatch) => {
        const token = getAuthToken();

        try {
            if (token !== null) {
                await logout(token);

                if (window.fcWidget && window.fcWidget.user) {
                    window.fcWidget.user.clear().then(
                        () => {},
                        () => {}
                    );
                }

                window.location.replace(ROUTES.HOME);
            }
        } catch (err) {
            //
        }

        dispatch(authLogoutStart());
        deleteAuthToken();
        deleteTradeInInfo();
        dispatch(authLogoutSuccess());
    };
}

export function handleUpdateUserProfile(id, params) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await updateUserProfile(id, params);

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
        }
    };
}

export const handleOpenSignUp = () => ({
    type: OPEN_SIGNUP
});

export const handleCloseSignUp = () => ({
    type: CLOSE_SIGNUP
});

// eslint-disable-next-line valid-jsdoc
/**
 * add items to cart
 * @param {Object} form
 * @param {Boolean} form.include_noa
 * @param {Function} onSuccess
 * @param {Function} onFailure
 * @returns {Promise}
 */
export const handleAuthorizeSingpass = (form, onSuccess = () => { }, onFailure = () => { }, isRefresh) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/authorize/singpass', form);

            if (isRefresh) {
                dispatch(showSubmit(true));
            }

            onSuccess(data?.redirect_url);
        } catch (e) {
            onFailure();
            dispatch(showSubmit(false));
        }
    };
};

/**
* @param {String} code
* @param {Function} onSuccess
* @param {Function} onFailure
* @returns {Promise}
*/

export const handleGetSubmissionKey = (code, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/authorize/singpass/person', { code });

            // console.log(data);
            onSuccess(data);
        } catch (e) {
            onFailure();
        }
    };
};

/**
 * add items to cart
 * @param {Object} form
 * @param {String} form.submission_key
 * @param {String} form.martial_status
 * @param {String} form.residency_status
 * @param {String} form.email_address
 * @param {String} form.mobile
 * @param {Function} onSuccess
 * @param {Function} onFailure
 * @returns {Promise}
 */
export const handleSingpassSubmitData = (form, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.post('/authorize/singpass/submit', form);

            // update user singpass status
            // dispatch(handleAutoLogin());
            // dispatch(showSubmit(false));
            onSuccess(data);
        } catch (e) {
            onFailure();
        }
    };
};

export const handleFetchSingpassData = (onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            const { data } = await instance.get('/authorize/singpass/index');

            dispatch(updateProfile({ singpass: data?.data }));
            onSuccess();
        } catch (e) {
            onFailure();
        }
    };
};

export const handleVerifySingpassOtp = (form, onSuccess = () => { }, onFailure = () => { }) => {
    return async (dispatch) => {
        const token = getAuthToken();
        const instance = createAxiosInstance(token);

        try {
            await instance.post('authorize/singpass/otp/submit', form);
            dispatch(handleAutoLogin());
            onSuccess();
        } catch (err) {
            const msg = err?.response?.data?.message;

            onFailure(msg || null);
        }
    };
};

const INITIAL_STATE = {
    isAppReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,
    openLogin: false,
    profile: {
        singpass: {}
    },
    token: '',
    showOTP: false,
    signUp: false,
    userDoLoggedin: false,
    // singPassVerified: false, // toggle to test singpass flow
    singpassSubmissionKey: null,
    showSubmit: localStorage.getItem('showSubmit') ? (localStorage.getItem('showSubmit') === 'true') : false,
    includeNoa: localStorage.getItem('includeNoa') ? (localStorage.getItem('includeNoa') === 'true') : false,
    isInvoiceModalOpen: false,
    orderId: '',
    invoiceUrl: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPEN_LOGIN:
            return {
                ...state,
                openLogin: action.openLogin
            };

        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false,
                token: ''
            };

        case AUTH_SUCCESS: {
            const { profile, userDoLoggedin, invited } = action;

            Sentry.configureScope((scope) => {
                scope.setUser({ id: profile.id, email: profile.email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                openLogin: false,
                token: action.token,
                profile,
                userDoLoggedin: !userDoLoggedin,
                invited
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                isAppReady: true
            };

        case SHOW_OTP:
            return {
                ...state,
                showOTP: true,
                isAuthenticating: false
            };
        case HIDE_OTP:
            return {
                ...state,
                showOTP: false,
                isAuthenticating: false
            };

        case OPEN_SIGNUP:
            return {
                ...state,
                signUp: true
            };

        case CLOSE_SIGNUP:
            return {
                ...state,
                signUp: false
            };

        case UPDATE_SINGPASS_SUBMISSION_KEY:
            return {
                ...state,
                singpassSubmissionKey: action.payload
            };

        case SHOW_SUBMIT:
            localStorage.setItem('showSubmit', action.payload);

            return {
                ...state,
                showSubmit: action.payload
            };

        case NOA:
            localStorage.setItem('includeNoa', action.payload);

            return {
                ...state,
                includeNoa: action.payload
            };

        case ENABLESINGPASS:
            return {
                ...state,
                profile: { singpass_status: null }
            };

        case TOGGLE_INVOICE_MODAL:
            return {
                ...state,
                isInvoiceModalOpen: action.isOpen,
                orderId: action.orderId,
                invoiceUrl: action.invoiceUrl
            };

        default:
            return state;
    }
};
