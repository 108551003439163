import React from 'react';
import PropTypes from 'prop-types';

import './ReviewCard.scss';

ReviewCard.propTypes = {
    review: PropTypes.object
};

function ReviewCard({ review }) {
    return (
        <div className='review'>
            <div className='review__container'>
                <div className='review__container__rating'>
                    <span>{review.rating}/5</span>
                </div>
                {/* <div className='review__container__images d-flex'>
                    <img className='review__container__images__image' src='https://via.placeholder.com/50' alt='review' />
                    <img className='review__container__images__image' src='https://via.placeholder.com/50' alt='review' />
                    <img className='review__container__images__image' src='https://via.placeholder.com/50' alt='review' />
                </div> */}
                <div className='review__container__content'>
                    <p className='review__container__content__description'>{review.review}</p>
                    <p className='review__container__content__author'>{review.author}</p>
                </div>
            </div>
        </div>
    );
}

export default ReviewCard;
