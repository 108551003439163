import Button from 'containers/App/components/Button/Button';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import style from './OTPForm.module.scss';
import OtpInput from 'react-otp-input';
import { storeUserEvents } from 'api/user';
// import SingpassLogo from 'assets/images/general/smallIcon.png';

OTPForm.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    timer: PropTypes.number,
    resendCode: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    otp: PropTypes.string,
    setOtp: PropTypes.func,
    lastnumber: PropTypes.string,
    singpass: PropTypes.bool,
    email: PropTypes.string,
    showResend: PropTypes.bool,
    numInput: PropTypes.number,
    isReset: PropTypes.bool
};

function OTPForm({
    submit,
    changeForm,
    timer,
    resendCode,
    loading,
    type,
    otp,
    setOtp,
    lastnumber,
    email = '',
    singpass = false,
    showResend = true,
    numInput = 6,
    isReset
}) {
    const handleChange = (otp) => setOtp(otp);

    const handleSubmit = e => {
        e.preventDefault();
        submit(type);
    };

    useEffect(() => {
        storeUserEvents({ event: 'forgot_otp' });
    }, []);

    return (
        <div className={style.section}>
            <div className={style.title} >Enter OTP</div>

            <div className={style.counter}>{email ? `Email sent to user ${email}` : `SMS sent to phone number ending with ${lastnumber}`}</div>

            <form onSubmit={handleSubmit}>
                <div className={style.otpBox}>
                    <OtpInput
                        isInputNum={true}
                        containerStyle={style.container}
                        inputStyle={style.input}
                        value={otp}
                        onChange={handleChange}
                        numInputs={numInput}
                        separator={<span />} />
                </div>
                {/* {showResend && <div className={style.again} onClick={resendCode}>Send {email ? 'OTP' : 'SMS'} again {timer !== 0 ? timer : '' }</div>} */}
                <div className={style.buttons}>
                    <Button
                        loading={loading}
                        type='submit'
                        title={'Send OTP'}
                        className={'submit'} />

                </div>
            </form>
            {!singpass && !isReset &&
                <div className={style.account}>
                    <div className={style.account__title}>Back to</div>
                    <div className={!singpass ? style.account__button : style.account__singpassButton} onClick={() => changeForm('signin')}>
                        Sign in
                    </div>
                </div>
            }
        </div>
    );
}

export default OTPForm;
