import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import style from './ResetPassword.module.scss';
import eye from 'assets/images/general/eye.svg';
import Button from 'containers/App/components/Button/Button';
import { notifType, notify } from 'utils/notifSender';
import { storeUserEvents } from 'api/user';

ResetPassword.propTypes = {
    submit: PropTypes.func.isRequired,
    changeForm: PropTypes.func.isRequired,
    isReset: PropTypes.bool
};

function ResetPassword({ submit, changeForm, isReset }) {
    const [type, setType] = useState(true);
    const [typeConfirm, setTypeConfirm] = useState(true);

    const handleSubmit = e => {
        e.preventDefault();
        const pass = e.target.password.value;
        const passConf = e.target.confirmPassword.value;

        if (pass === '' || passConf === '') {
            notify('Fields should not be empty.', notifType.DANGER);

            return true;
        }

        if (pass !== passConf) {
            notify('Passwords do not match.', notifType.DANGER);

            return true;
        }

        submit(pass);
    };

    useEffect(() => {
        storeUserEvents({ event: 'reset_password' });
    }, []);

    return (
        <div className={style.section}>
            <div className={style.title} >Reset Password?</div>
            <form onSubmit={handleSubmit} >
                <div className={style.row}>
                    <div className={style.label}>
                        New password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='password'
                                className={style.value__input + ' ' + style.withIcon__input}
                                type={type ? 'password' : 'text'} />
                            <img
                                src={eye}
                                alt='Toggle password visibility button icon'
                                onClick={() => setType(!type)}
                                className={style.withIcon__img} />
                        </div>
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.label}>
                        Confirm Password
                    </div>
                    <div className={style.value}>
                        <div className={style.withIcon}>
                            <input
                                name='confirmPassword'
                                className={style.value__input + ' ' + style.withIcon__input}
                                type={typeConfirm ? 'password' : 'text'} />
                            <img
                                src={eye}
                                alt='Toggle password visibility button icon'
                                onClick={() => setTypeConfirm(!typeConfirm)}
                                className={style.withIcon__img} />
                        </div>
                    </div>
                </div>

                <div className={style.buttons}>
                    <Button
                        type='submit'
                        title={'Save'}
                        className={'submit'} />

                </div>
            </form>
            {!isReset && <div className={style.account}>
                <div className={style.account__title}>Back to</div>
                <div className={style.account__button} onClick={() => changeForm('signin')}>Sign in</div>
            </div>}
        </div>
    );
}

export default ResetPassword;
