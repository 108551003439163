import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { ROUTES } from 'config/constants';

import { getExploreCategories } from 'api/categories';
import { getBrands } from 'api/brands';

import AppLoading from '../Loading/AppLoading';
import { AuthedRoute } from './components/RouteWrappers';
import Layout from '../Layout/Layout';
import DealsProductContainer from 'containers/DealsProduct/DealsProductContainer';
import FlashDealsProductContainer from 'containers/FlashDealsProduct/FlashDealsProductContainer';
import ContactUsContainer from 'containers/ContactUs/ContactUsContainer';
import AddReviewContainer from 'containers/AddReview/AddReviewContainer';

import {
    HomeContainer,
    InviteContainer,
    PageNotFoundContainer,
    ProductContainer,
    OrderedProductContainer,
    ProductsContainer,
    DealsContainer,
    CartContainer,
    ShippingContainer,
    PaymentContainer,
    OrderSummaryContainer,
    AboutUsContainer,
    TermsAndConditionsContainer,
    FAQContainer,
    PrivacyPolicyContainer,
    ForBusinessClientsContainer,
    HowItWorksContainer,
    SingPassContainer,
    ProfileContainer,
    BillingHistoryContainer,
    SingpassManualFormContainer,
    SingpassResultContainer,
    MyInformationContainer,
    SubscriptionsContainer,
    InactiveSubscriptionsContainer,
    OrdersContainer,
    PaymentInfoContainer,
    ShippingInfoContainer,
    ReferAndEarnContainer,
    LuckyDrawContainer,
    AppleVisionProContainer,
    AssortmentContainer,
    SearchContainer,
    InvoiceContainer
} from './config/routes';
import OrderSummaryScreenConfirmation from 'screens/OrderSummaryScreenConfirmation/OrderSummaryScreenConfirmation';
import ImeiLookup from 'containers/ImeiLookup/ImeiLookup';

function AppRouter({ isAuthed }) {
    const location = useLocation();
    const matchAssortmentPage = useRouteMatch(ROUTES.ASSORTMENT_PAGE);
    const [productCategories, setProductCategories] = useState([]);
    const [allBrands, setAllBrands] = useState([]);

    useEffect(() => {
        if (location.pathname === ROUTES.HOME || matchAssortmentPage) loadAllBrands();
        if (location.pathname === ROUTES.HOME || location.pathname === ROUTES.PRODUCTS || location.pathname.indexOf('search') > -1) loadAllProductsCategories();
    }, []);

    const loadAllProductsCategories = async () => {
        const allPromise = Promise.all([
            getExploreCategories()
        ]);

        const promises = await allPromise;

        setProductCategories(promises[0]);
        localStorage.setItem('basicCategories', JSON.stringify(productCategories));
    };

    const loadAllBrands = async () => {
        const allBrands = await getBrands();

        setAllBrands(allBrands);
        localStorage.setItem('basicBrands', JSON.stringify(allBrands));
    };

    return (
        <Suspense fallback={<AppLoading />}>
            <QueryParamProvider ReactRouterRoute={Route}>
                <Layout productCategories={productCategories}>
                    <Switch>
                        <Route
                            path={ROUTES.HOME}
                            exact={true}
                            render={props => (
                                <HomeContainer
                                    {...props}
                                    productCategories={productCategories}
                                    isAuthed={isAuthed}/>
                            )}/>
                        <Route
                            path={ROUTES.INVITE}
                            exact={true}
                            component={InviteContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.PRODUCTS}
                            exact={true}
                            render={props => (
                                <ProductsContainer
                                    {...props}
                                    productCategories={productCategories}
                                    isAuthed={isAuthed} />
                            )}/>
                        <Route
                            path={ROUTES.ASSORTMENT_PAGE}
                            exact={true}
                            render={props => (
                                <AssortmentContainer
                                    {...props}
                                    productCategories={productCategories}
                                    isAuthed={isAuthed} />
                            )}/>
                        <Route
                            path={`${ROUTES.PRODUCT.path}/:rental_term?`}
                            exact={true}
                            render={(props) => (
                                <ProductContainer {...props} isAuthed={isAuthed} />
                            )}/>
                        <Route
                            path={`${ROUTES.SEARCH.path}:query?`}
                            exact={true}
                            render={(props) => (
                                <SearchContainer productCategories={productCategories} {...props} isAuthed={isAuthed} />
                            )}/>
                        <Route
                            path={ROUTES.DEALS}
                            exact={true}
                            component={DealsContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={`${ROUTES.DEAL.path}/:rental_term?`}
                            exact={true}
                            render={(props) => (
                                <DealsProductContainer
                                    {...props}
                                    isAuthed={isAuthed}/>
                            )}/>
                        <Route
                            path={`${ROUTES.FLASH_DEAL.path}/:rental_term?`}
                            exact={true}
                            render={(props) => (
                                <FlashDealsProductContainer
                                    {...props}
                                    isAuthed={isAuthed}/>
                            )}/>
                        <Route
                            path={ROUTES.ABOUT_US}
                            exact={true}
                            component={AboutUsContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.CONTACTUS}
                            exact={true}
                            component={ContactUsContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.ADDREVIEW}
                            exact={true}
                            component={AddReviewContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.FAQ}
                            exact={true}
                            component={FAQContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.TERMS_AND_CONDITIONS}
                            exact={true}
                            component={TermsAndConditionsContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.PRIVACY_POLICY}
                            exact={true}
                            component={PrivacyPolicyContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.FOR_BUSINESS}
                            exact={true}
                            component={ForBusinessClientsContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.HOW_IT_WORKS}
                            exact={true}
                            component={HowItWorksContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.IMEI_LOOKUP}
                            exact={true}
                            component={ImeiLookup}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.MY_ACCOUNT}
                            exact={true}
                            component={ProfileContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.PROFILE}
                            exact={true}
                            component={ProfileContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.BILLING_HISTORY}
                            exact={true}
                            component={BillingHistoryContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.SINGPASS}
                            exact={true}
                            component={SingPassContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.CART}
                            exact={true}
                            component={CartContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.SHIPPING}
                            exact={true}
                            component={ShippingContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.PAYMENT}
                            exact={true}
                            component={PaymentContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.ORDER_SUMMARY}
                            exact={true}
                            component={OrderSummaryContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.ORDER_CONFIRMATION}
                            exact={true}
                            component={OrderSummaryScreenConfirmation}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.ORDEREDPRODUCT()}
                            exact={true}
                            component={OrderedProductContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.SINGPASS_MANUALL_FORM}
                            exact={true}
                            component={SingpassManualFormContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.SINGPASS_RESULT}
                            exact={true}
                            component={SingpassResultContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.MYINFORMATION}
                            exact={true}
                            component={MyInformationContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.SUBSCRIPTIONS}
                            exact={true}
                            component={SubscriptionsContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.INACTIVE_SUBSCRIPTIONS}
                            exact={true}
                            component={InactiveSubscriptionsContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.ORDERS}
                            exact={true}
                            component={OrdersContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.PAYMENTINFO}
                            exact={true}
                            component={PaymentInfoContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.SHIPPINGINFO}
                            exact={true}
                            component={ShippingInfoContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.ORDER()}
                            exact={true}
                            component={BillingHistoryContainer}
                            isAuthed={isAuthed}/>
                        <AuthedRoute
                            path={ROUTES.REFER_AND_EARN}
                            exact={true}
                            component={ReferAndEarnContainer}
                            isAuthed={isAuthed} />
                        <AuthedRoute
                            path={ROUTES.INVOICE()}
                            exact={true}
                            component={InvoiceContainer}
                            isAuthed={isAuthed} />
                        <Route
                            path={ROUTES.LUCKY_DRAW}
                            exact={true}
                            component={LuckyDrawContainer}
                            isAuthed={isAuthed}/>
                        <Route
                            path={ROUTES.APPLE_VISION_PRO}
                            exact={true}
                            render={props => (
                                <AppleVisionProContainer {...props} isAuthed={isAuthed} />
                            )}/>
                        <Route component={PageNotFoundContainer} />
                    </Switch>
                </Layout>
            </QueryParamProvider>
        </Suspense>
    );
}

AppRouter.propTypes = {
    isAuthed: PropTypes.bool.isRequired
};

export default AppRouter;
